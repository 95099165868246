<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="news-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" :max-items="searchVar.itemsPerPage" :data="news">  <!-- multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="news">-->

      <div slot="header" class="flex flex-wrap items-center flex-grow">
        <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
        <v-select style="width: 100px" placeholder="카테고리" label="name" :reduce="name => name.code" v-model="category1"
          :options="categories1"/>
        <v-select style="width: 100px" placeholder="검색옵션" label="name" :clearable="false" v-model="searchType"
          :options="[{name: '제목', code: 'title'},{name: '본문', code: 'description'}]"/>
        <div style="padding-top: 0;">
          <vs-input class="inputx" placeholder="기사 검색" v-model="searchText" @keyup.enter="searchNewsList(searchVar)"/>
        </div>
        <vs-button style="width: 50px;" color="primary" type="border" class="p-2 cursor-pointer" @click="searchNewsList(searchVar)">검색</vs-button>
        <div  id="data-list-search-rank" style="padding-top: 0;">
          <div class="p-2 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * searchVar.itemsPerPage) + 1 }} - {{ numOfNews >= searchVar.itemsPerPage ? (currentx * searchVar.itemsPerPage) : numOfNews }} of {{ numOfNews }}</span>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th>기사 id</vs-th>
        <vs-th>기사 제목</vs-th>
        <vs-th>작성시간</vs-th>
        <vs-th>카테고리</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td><p @click="showNews(indextr, tr.idx)">{{ tr.idx }}</p></vs-td>
          <vs-td><div @click="showNews(indextr, tr.idx)"><span v-html="tr.title"/></div></vs-td>
          <vs-td>{{ tr.d_pass }}</vs-td>
          <vs-td>{{ makeYnewsCategoryText(tr.menu_code) }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>

    <!-- Start: 뉴스 상세보기 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
        <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0;">
          <vs-button vs-type="gradient" color="success" icon="create" @click="writeNews(selectedId)">기사 작성</vs-button>
        </div>
        <div class="items-center">
          <div class="news-detail" id="view_con" style="margin: 20px 0px 0px;">
            <h2 v-html="news[selectedIndex].title"/>
            <p v-html="newsDetail.data.split('\n').join('<br>')"/>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 뉴스 상세보기 팝업 -->


  </div>
</template>

<script>
import moment from 'moment';

export default {
  components: {
  },
  data() {
    return {
      selectedPortalsToDelete: [],
      portalDeleteActive: false,
      selectedIndex: 0,
      selectedId: 0,
      news: [{descr: ''}],
      isHuboNews: [],
      isHuboNewsSnapshot: [],
      currentx: 1,
      numOfNews: 0,
      numOfPages: 0,
      sentNewsflashCnt: 0,
      searchVar: {
        startRow: 0,
        itemsPerPage: 15,
        state: null,
        category1: null,
        series: null,
        rank: null,
        writer: null,
        searchType: 'title',
        searchText: '',
        startDate: moment().subtract(2, 'week').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        department: '',
      },
      state: null,
      category1: null,
      series: null,
      rank: null,
      writer_s: null,
      searchType: {name: '제목', code: 'title'},
      searchText: '',
      dateRange: { // used for v-model prop
          startDate: moment().subtract(2, 'week'),
          endDate: moment(),
      },
      dateRange2: [new Date(moment().subtract(2, 'week')), new Date(moment())],
      newsDetail: {data: ''},
      writer: [],
      types: [],
      states: [],
      categories1: [],
      categories1_ok: false,
      categories2: [],
      categories2_view: [],
      seriesArray: [],
      category2Disable: true,
      portals: [],
      portalSelect: [],
      isMounted: false,
      addNewDataSidebar: false,
      newsDetailModalActive: false,
    }
  },
  beforeRouteLeave (to, from, next) {
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
      else {
        this.searchVar.startRow = 0;
        this.currentx = 1;
        if(this.$route.query.startRow != 0){
          this.state= null;
          this.category1= null;
          this.series= null;
          this.rank= null;
          this.writer_s= null;
          this.searchText= '';
        }
      }
      if(this.$route.query.state) this.searchVar.state = this.$route.query.state;
      else this.searchVar.state = null;
      if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
      else this.searchVar.category1 = null;
      if(this.$route.query.series) this.searchVar.series = this.$route.query.series;
      else this.searchVar.series = null;
      if(this.$route.query.rank) this.searchVar.rank = this.$route.query.rank;
      else this.searchVar.rank = null;
      if(this.$route.query.writer) this.searchVar.writer = this.$route.query.writer;
      else this.searchVar.writer = null;
      if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
      else this.searchVar.searchText = '';
      if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
      else this.searchVar.startDate = moment().subtract(2, 'week').format('YYYY-MM-DD');
      if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
      else this.searchVar.endDate = moment().format('YYYY-MM-DD');
      if(this.$route.query.startDate && this.$route.query.endDate){
        this.dateRange2 = [new Date(this.$route.query.startDate), new Date(this.$route.query.endDate)]
      }
      this.getNewsList(this.searchVar);
    },
    currentx: function() {
      let currentx = this.currentx;
      if(currentx <= 0){ currentx = 1 }
      this.searchVar.startRow = (currentx-1) * this.searchVar.itemsPerPage;
      window.scrollTo(0,0);
      console.log(this.searchVar)
      this.$router.push({path: '/yonhapList', query: this.searchVar})
      // this.getNewsList(this.searchVar);
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    makeYnewsCategoryText(menu_code){
      let category;
      switch(menu_code){
        case 'p01' : category = "정치"; break;
        case 'p02' : category = "경제"; break;
        case 'p03' : category = "사회"; break;
        case 'p04' : category = "문화"; break;
        case 'p05' : category = "체육"; break;
        case 'p06' : category = "외신"; break;
        case 'p07' : category = "지방"; break;
        case 'p08' : category = "기타"; break;
        case 'p09' : category = "산업"; break;
        default : category = "일반"; break;
      }
      return category;
    },
    makeCategoryCode(menu_code){
      let category;
      switch(menu_code){
        case 'p01' : category = "EV"; break;
        case 'p02' : category = ""; break;
        case 'p03' : category = "EV"; break;
        case 'p04' : category = "CUL"; break;
        case 'p05' : category = "CUL"; break;
        case 'p06' : category = ""; break;
        case 'p07' : category = ""; break;
        case 'p08' : category = ""; break;
        case 'p09' : category = ""; break;
        default : category = ""; break;
      }
      return category;
    },
    makeWriteData(newsId){
      const selectedNewsData = this.news.find(e => e.idx == newsId);
      return {
        mainTitle: selectedNewsData.title, subTitle: "", text: this.newsDetail.data, category1: {code: this.makeCategoryCode(selectedNewsData.menu_code)}, mno1: this.activeUserInfo.id,
        state: {code: 9}, images: [], tag: [], rel: [],
        writer: this.activeUserInfo.id, d_pass: moment(), name: this.activeUserInfo.name, tailname: this.activeUserInfo.tail
      }
    },
    writeNews(newsId){
      const thisIns = this;
      console.log("write: " + newsId);
      this.newsDetailModalActive = false;
      this.$http.post('/api/writeArticles', this.makeWriteData(newsId)).then(function (response) {
        thisIns.$router.push({path: '/writeArticles', query: { id: response.data.id}});
      })
    },
    showNews(index, newsId){
      this.newsDetailModalActive = true;
      this.selectedIndex = index;
      this.selectedId = newsId;
      console.log(this.selectedIndex, this.selectedId);
      this.newsDetail = {data: ''};
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getYonhapNews/detail', {id: newsId})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      // this.getNewsList(this.searchVar)
    },
    searchNewsList(data){
      data.startRow = 0;
      // console.log(this.state)
      data.state = this.state;
      data.category1 = this.category1;
      data.series = this.series;
      data.rank = this.rank;
      data.writer = this.writer_s;
      data.searchText = this.searchText;
      data.searchType = this.searchType.code;
      data.startDate = moment(this.dateRange2[0]).format('YYYY-MM-DD');
      data.endDate = moment(this.dateRange2[1]).format('YYYY-MM-DD');
      data.department = '';
      this.$router.push({path: '/yonhapList', query: data})
      this.currentx = 1;
      // this.getNewsList(data);
    },
    getNewsList(data) {
      console.log(data.state, data.category1, data.writer, data.series)

      data.level = this.activeUserInfo.level
      data.id = this.activeUserInfo.id
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getYonhapNews/list', data)
        .then(function (response) {
          // console.log(response);
          if(response.data[0][0].news_count){
            thisIns.numOfNews = response.data[0][0].news_count;
            thisIns.numOfPages = Math.ceil(response.data[0][0].news_count / thisIns.searchVar.itemsPerPage);
            thisIns.news = response.data[1];

          }else{
            thisIns.numOfNews = 0;
            thisIns.numOfPages = 0;
            thisIns.news = [{descr: ''}];
          }
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.categories1 = [
      {code: '', name: '전체'},
      {code: 'p01', name: '정치'},
      {code: 'p02', name: '경제'},
      {code: 'p03', name: '사회'},
      {code: 'p04', name: '문화'},
      {code: 'p05', name: '체육'},
      {code: 'p06', name: '외신'},
      {code: 'p07', name: '지방'},
      {code: 'p08', name: '기타'},
      {code: 'p09', name: '산업'},
    ]
    const tmpPage = parseInt(this.$route.query.startRow / this.$route.query.itemsPerPage + 1);
    if(tmpPage > 1) this.currentx = tmpPage;
    this.searchVar.department = '';
    if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
    if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
    if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
    if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
    if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
    this.getNewsList(this.searchVar);
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-newslist.scss";
</style>
